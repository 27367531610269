import React, { FunctionComponent, useEffect, useState } from 'react'
import Script from 'next/script'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  FormControl,
  FormErrorMessage,
  Input,
  Button,
  Divider,
  Link,
  Stack,
  ModalCloseButton,
  useBreakpointValue,
  Box,
  VStack,
  Center,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  FormLabel,
} from '@chakra-ui/react'
import * as yup from 'yup'
import AtgForgotPassword from 'composable/components/account/atg-forgot-password'
import AtgLoginPage from 'composable/components/account/atg-login-page'
import { AlertBox } from 'composable/components/alert-box'
import { BORDERFREE_ORDER_TRACKING_URL } from 'composable/components/general/constants'
import { useFormat } from 'helpers/hooks/useFormat'
import { utagLink } from 'helpers/tealiumHelper'
import { useLocale } from 'frontastic/contexts'
import { getOrderDetails } from 'frontastic/actions/advancedOrderSearch'
import { useAccountCT } from 'frontastic/contexts'

interface ErrorState {
  orderNumber?: string
  email?: string
}

type OrderStatusModalProps = {
  isOpen: boolean
  onClose: () => void
  finalFocus?: React.RefObject<HTMLElement>
}

const tabValue = ['US', 'International']

const OrderStatusModal: FunctionComponent<OrderStatusModalProps> = ({ isOpen, onClose, finalFocus }) => {
  const [searchOption, setSearchOption] = useState(tabValue[0])
  const [orderNumber, setOrderNumber] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState<ErrorState>({})
  const [touched, setTouched] = useState({ orderNumber: false, email: false })
  const [showSignInModal, setShowSignInModal] = useState(false)
  const { formatMessage } = useFormat({ name: 'common' })
  const orderNumberError = formatMessage({ id: 'validation.orderNumberRequired' })
  const emailError = formatMessage({ id: 'validation.emailRequired' })
  const [displayStyle, setDisplayStyle] = useState('block')
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
  const { loggedIn, account } = useAccountCT()
  const { country, currency } = useLocale()
  const [showAlertMsg, setShowAlertMsg] = useState(false)

  const validationSchema = yup.object().shape({
    orderNumber: yup.string().required(formatMessage({ id: 'validation.orderNumberRequired' })),
    email: yup
      .string()
      .email(formatMessage({ id: 'validation.emailValidAddress' }))
      .required(formatMessage({ id: 'validation.emailRequired' })),
  })

  if (loggedIn && loggedIn !== undefined) {
    validationSchema.fields.email = yup.string()
  }

  const handleSearchOptionChange = (value) => {
    setSearchOption(tabValue[value])
    resetForm()
  }

  useEffect(() => {
    if (isOpen) {
      setShowAlertMsg(false)
      utagLink(
        [
          'link',
          {
            tealium_event: 'order_status',
          },
        ],
        country || 'US',
        currency || 'USD',
      )
    }
  }, [isOpen])

  useEffect(() => {
    if (searchOption === 'US') {
      setDisplayStyle('none')
    } else {
      setDisplayStyle('block')
      const win: any = window
      const container = document.getElementById('bfTTContainer')
      if (container && !container.innerHTML) {
        const intervalId = setInterval(() => {
          if (typeof win.loadTrackingTool === 'function') {
            clearInterval(intervalId)
            win.loadTrackingTool()
          }
        }, 200)

        return () => clearInterval(intervalId)
      } else if (container && container.innerHTML) {
        win.loadTrackingTool()
      }
    }
  }, [searchOption])

  const resetForm = () => {
    setOrderNumber('')
    setEmail('')
    setError({})
    setTouched({ orderNumber: false, email: false })
    setShowAlertMsg(false)
  }

  const handleInputChange = (field, value) => {
    if (field === 'orderNumber') {
      setOrderNumber(value)
    }
    if (field === 'email') {
      setEmail(value)
    }
    setError({ ...error, [field]: '' })
  }

  const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true })
    if (field === 'orderNumber') {
      if (!orderNumber.trim()) {
        setError({ ...error, orderNumber: orderNumberError })
      }
      if (orderNumber.trim() && orderNumber.length < 5) {
        const orderNumberMinimum = formatMessage({ id: 'validation.orderNumberMinimum' })
        setError({ ...error, orderNumber: orderNumberMinimum })
      }
    }
    if (!email.trim() && field === 'email') {
      setError({ ...error, email: emailError })
    }
  }

  const handleErrors = () => {
    setShowAlertMsg(true)
    setTouched({ ...touched, orderNumber: true })
    setError({ ...error, orderNumber: ' ' })

    if (!loggedIn) {
      setTouched((prev) => ({ ...prev, email: true }))
      setError((prev) => ({ ...prev, email: ' ' }))
    }
  }

  const getDetails = async (payload: any) => {
    try {
      const response = await getOrderDetails(payload)
      if (response && response?.rootData) {
        const isLoggedInEmail = account?.email === response?.rootData?.CustomerEmail
        const isGuestEmail = email?.trim() === response?.rootData?.CustomerEmail

        let url = ''
        if (loggedIn && isLoggedInEmail) {
          url = `/order-details?orderId=${orderNumber}&encrypted=false`
        } else if (!loggedIn && isGuestEmail) {
          url = `/order-details?orderId=${orderNumber}&email=${email}&encrypted=false`
        } else {
          handleErrors()
          return
        }
        window.location.href = `${window?.location?.origin}${url}`
        setShowAlertMsg(false)
        onClose()
      } else {
        handleErrors()
      }
    } catch (error) {
      handleErrors()
    }
  }

  const handleUSOrderSubmit = async () => {
    try {
      loggedIn
        ? await validationSchema.validate({ orderNumber }, { abortEarly: false })
        : await validationSchema.validate({ orderNumber, email }, { abortEarly: false })
      getDetails({ orderId: orderNumber })
    } catch (err) {
      const errors = {}
      err.inner.forEach((e) => {
        errors[e.path] = e.message
      })
      setError(errors)
    }
  }

  const openLoginModal = () => {
    setShowSignInModal(true)
  }

  const openLoginModalOnEnter = (event) => {
    if (event?.key === 'Enter') {
      setTimeout(() => {
        setShowSignInModal(true)
      }, 300)
    }
  }

  const closeModal = () => {
    setShowSignInModal(false)
  }

  const viewOrderHistory = () => {
    const url = `/myaccount/myOrderHistory`
    window.location.href = url
    onClose()
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (searchOption === 'US') {
      handleUSOrderSubmit()
    }
  }

  const toggleForgotPassword = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal)
    if (showSignInModal) {
      closeModal()
    }
  }

  const radioLayout = useBreakpointValue({ base: 'column', md: 'row' })
  const isDisabled = loggedIn
    ? !orderNumber || orderNumber.length < 5
    : !orderNumber || !email || orderNumber.length < 5

  if (showSignInModal) {
    return <AtgLoginPage isOpen={showSignInModal} onClose={closeModal} toggleForgotPassword={toggleForgotPassword} />
  }
  if (showForgotPasswordModal) {
    return <AtgForgotPassword isOpen={showForgotPasswordModal} onClose={toggleForgotPassword} />
  }

  return (
    <Modal finalFocusRef={finalFocus} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent m={4}>
        <ModalHeader
          borderBottom={'1px'}
          borderColor={'surface.border'}
          fontSize={'20px'}
          fontWeight={'bold'}
          textAlign={'left'}
          p={4}
          pl={6}
        >
          {formatMessage({ id: 'text.orderStatus.title' })}
        </ModalHeader>
        <ModalCloseButton size={'md'} _hover={{ backgroundColor: 'none !important', opacity: '0.9 !important' }} />
        <ModalBody p={0}>
          <VStack alignItems={'flex-start'} gap={5} px={6} py={5}>
            <Tabs onChange={handleSearchOptionChange} width={'100%'}>
              <TabList borderBottom={'2px'} borderColor={'surface.border'} w={'100%'}>
                <Tab
                  flex="1"
                  value={'US'}
                  px={4}
                  py={2}
                  fontSize={'sm'}
                  color={'text.primary'}
                  fontWeight={'normal'}
                  _selected={{ fontWeight: 'semiBold', borderBottom: '2px solid #000' }}
                  _hover={{ color: 'text.primary !important' }}
                >
                  {formatMessage({ id: 'radio.orderStatus.option1' })}
                </Tab>
                <Tab
                  flex="1"
                  value={'International'}
                  px={4}
                  py={2}
                  fontSize={'sm'}
                  color={'text.primary'}
                  fontWeight={'normal'}
                  _selected={{ fontWeight: 'semiBold', borderBottom: '2px solid #000' }}
                  _hover={{ color: 'text.primary !important' }}
                >
                  {formatMessage({ id: 'radio.orderStatus.option2' })}
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel padding={0}>
                  {showAlertMsg && (
                    <Box>
                      <AlertBox
                        title={formatMessage({
                          id: 'orderStatus.error.msg.title',
                        })}
                        description={
                          loggedIn
                            ? formatMessage({
                                id: 'orderStatus.error.msg.loggedin',
                              })
                            : formatMessage({
                                id: 'orderStatus.error.msg.guest',
                              })
                        }
                        rootProps={{
                          bg: 'ui.error.light',
                          status: 'error',
                          mt: '20px',
                        }}
                        closeButtonProps={{ display: 'none' }}
                      />
                    </Box>
                  )}
                  <Box>
                    <form onSubmit={handleSubmit}>
                      <VStack alignItems={'flex-start'} gap={5} w={'100%'}>
                        <Text fontSize={'1rem'} mt={5}>
                          {loggedIn
                            ? formatMessage({ id: 'text.orderStatus.subTitle' })
                            : formatMessage({ id: 'text.orderStatus.subTitle2' })}
                        </Text>
                        <VStack gap={5} w={'100%'}>
                          <FormControl isInvalid={touched.orderNumber && !!error.orderNumber}>
                            <FormLabel
                              htmlFor={'orderNumber'}
                              textStyle={'heading-desktop-75'}
                              color={'text.primary'}
                              m={0}
                              display="flex"
                              alignItems="center"
                              mb={2}
                            >
                              <Text textStyle={'heading-desktop-75'}>{`${formatMessage({
                                id: 'label.orderStatus.orderNumber',
                              })}*`}</Text>
                            </FormLabel>
                            <Input
                              placeholder={formatMessage({ id: 'label.orderStatus.orderNumber' })}
                              value={orderNumber}
                              onChange={(e) => handleInputChange('orderNumber', e.target.value)}
                              onBlur={() => handleBlur('orderNumber')}
                              isRequired
                              borderRadius={'6px'}
                              borderColor={'shading.400'}
                              px={4}
                              py={2}
                              fontWeight={'normal'}
                              _focus={{
                                boxShadow: 'none',
                                borderWidth: '2px',
                                borderColor: 'black',
                              }}
                              height={'40px'}
                              id="orderNumber"
                            />
                            <FormErrorMessage>{error?.orderNumber}</FormErrorMessage>
                          </FormControl>
                          {loggedIn ? null : (
                            <FormControl isInvalid={touched.email && !!error.email}>
                              <FormLabel
                                htmlFor={'email'}
                                textStyle={'heading-desktop-75'}
                                color={'text.primary'}
                                m={0}
                                mb={2}
                                display="flex"
                                alignItems="center"
                              >
                                <Text textStyle={'heading-desktop-75'}>{`${formatMessage({
                                  id: 'label.orderStatus.email',
                                })}*`}</Text>
                              </FormLabel>
                              <Input
                                placeholder={formatMessage({ id: 'label.orderStatus.email' })}
                                value={email}
                                onChange={(e) => handleInputChange('email', e.target.value)}
                                onBlur={() => handleBlur('email')}
                                borderRadius={'6px'}
                                borderColor={'shading.400'}
                                fontWeight={'normal'}
                                isRequired
                                _focus={{
                                  boxShadow: 'none',
                                  borderWidth: '2px',
                                  borderColor: 'black',
                                }}
                                height={'40px'}
                              />
                              <FormErrorMessage>{error?.email}</FormErrorMessage>
                            </FormControl>
                          )}
                        </VStack>
                        <Button
                          type="submit"
                          w="full"
                          onClick={handleSubmit}
                          textAlign="center"
                          size={'md'}
                          isDisabled={isDisabled}
                          color={'#fff'}
                          _disabled={{
                            opacity: '0.65',
                          }}
                          _hover={{
                            backgroundColor: 'none',
                          }}
                        >
                          {formatMessage({ id: 'cta.orderStatus.showOrder' })}
                        </Button>

                        <Divider></Divider>
                        <Text textAlign={'left'} fontSize={'sm'}>
                          {formatMessage({ id: 'text.orderStatus.footerText2' })}
                          <Link _hover={{ color: '#6b6b6b' }} textDecoration={'underline'} href="tel:1.855.746.7395">
                            {formatMessage({ id: 'orderStatus.footerLinks' })}
                          </Link>
                        </Text>
                      </VStack>
                    </form>
                  </Box>
                </TabPanel>
                <TabPanel padding={0}>
                  <Box>
                    <Center>
                      <Box
                        id="bfTTContainer"
                        marginLeft={{ md: '-10px' }}
                        width={{ base: '100%', md: '29rem !important' }}
                        overflow={'hidden'}
                      ></Box>
                    </Center>
                    <Divider></Divider>
                    <Stack gap={2} mt={5}>
                      <Text fontSize={'sm'} fontWeight={'semiBold'} textTransform={'capitalize'}>
                        {formatMessage({ id: 'orderStatus.internationalOrder.title' })}
                      </Text>

                      <Text fontSize={'sm'}> {formatMessage({ id: 'orderStatus.internationalOrder.info' })}</Text>
                      <Text fontSize={'sm'}>
                        {formatMessage({ id: 'orderStatus.internationalOrder.info2' })}
                        <Link _hover={{ color: '#6b6b6b' }} fontSize={'sm'} textDecoration={'underline'}>
                          {' '}
                          {formatMessage({ id: 'orderStatus.internationalOrder.info.email' })}.
                        </Link>
                      </Text>
                    </Stack>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </ModalBody>
      </ModalContent>
      <Script id="intlTrackScript" async={true} src={BORDERFREE_ORDER_TRACKING_URL}></Script>
    </Modal>
  )
}

export default OrderStatusModal
